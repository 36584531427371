.divHistoryPanelContainer{
    height: 72px;
}

.divHistoryPanel{
    @apply h-full flex justify-between max-w-md w-full
}
.divHistoryPanel div{
    @apply flex-1 w-full text-gray-900 p-2 rounded-lg bg-white my-1 flex justify-between items-center max-w-96
}

.divHistoryPanel button{
    @apply px-5 
}



.hidden{
    display: none;
}


.dot{
    @apply size-4 bg-yellow-600 rounded-full
}

.info-window-table{
    @apply border w-full text-sm text-left rtl:text-right bg-white
}

.gm-ui-hover-effect {
    display: none !important;
}

.gm-style-iw {
    max-width: 320px !important; 
}

.info-window-heading{
    @apply text-2xl font-semibold flex justify-between items-center
}

.info-window-heading button{
    @apply py-4 pl-4 focus-visible:outline-none
}

.coloumBold{
    @apply font-medium 
}

.reportTime{
    font-size: 16px;
    @apply font-semibold
}

.angle_arrow{
    height: 15px;
    width: 15px;
    position: relative;
    padding: 0.5px;
    border-radius: 50%;
    top: 5px;
    background-color: yellow;
}



.angle-0 {
    transform: rotate(calc(0deg - 90deg));
}
.angle-1 {
    transform: rotate(calc(1deg - 90deg));
}
.angle-2 {
    transform: rotate(calc(2deg - 90deg));
}
.angle-3 {
    transform: rotate(calc(3deg - 90deg));
}
.angle-4 {
    transform: rotate(calc(4deg - 90deg));
}
.angle-5 {
    transform: rotate(calc(5deg - 90deg));
}
.angle-6 {
    transform: rotate(calc(6deg - 90deg));
}
.angle-7 {
    transform: rotate(calc(7deg - 90deg));
}
.angle-8 {
    transform: rotate(calc(8deg - 90deg));
}
.angle-9 {
    transform: rotate(calc(9deg - 90deg));
}
.angle-10 {
    transform: rotate(calc(10deg - 90deg));
}
.angle-11 {
    transform: rotate(calc(11deg - 90deg));
}
.angle-12 {
    transform: rotate(calc(12deg - 90deg));
}
.angle-13 {
    transform: rotate(calc(13deg - 90deg));
}
.angle-14 {
    transform: rotate(calc(14deg - 90deg));
}
.angle-15 {
    transform: rotate(calc(15deg - 90deg));
}
.angle-16 {
    transform: rotate(calc(16deg - 90deg));
}
.angle-17 {
    transform: rotate(calc(17deg - 90deg));
}
.angle-18 {
    transform: rotate(calc(18deg - 90deg));
}
.angle-19 {
    transform: rotate(calc(19deg - 90deg));
}
.angle-20 {
    transform: rotate(calc(20deg - 90deg));
}
.angle-21 {
    transform: rotate(calc(21deg - 90deg));
}
.angle-22 {
    transform: rotate(calc(22deg - 90deg));
}
.angle-23 {
    transform: rotate(calc(23deg - 90deg));
}
.angle-24 {
    transform: rotate(calc(24deg - 90deg));
}
.angle-25 {
    transform: rotate(calc(25deg - 90deg));
}
.angle-26 {
    transform: rotate(calc(26deg - 90deg));
}
.angle-27 {
    transform: rotate(calc(27deg - 90deg));
}
.angle-28 {
    transform: rotate(calc(28deg - 90deg));
}
.angle-29 {
    transform: rotate(calc(29deg - 90deg));
}
.angle-30 {
    transform: rotate(calc(30deg - 90deg));
}
.angle-31 {
    transform: rotate(calc(31deg - 90deg));
}
.angle-32 {
    transform: rotate(calc(32deg - 90deg));
}
.angle-33 {
    transform: rotate(calc(33deg - 90deg));
}
.angle-34 {
    transform: rotate(calc(34deg - 90deg));
}
.angle-35 {
    transform: rotate(calc(35deg - 90deg));
}
.angle-36 {
    transform: rotate(calc(36deg - 90deg));
}
.angle-37 {
    transform: rotate(calc(37deg - 90deg));
}
.angle-38 {
    transform: rotate(calc(38deg - 90deg));
}
.angle-39 {
    transform: rotate(calc(39deg - 90deg));
}
.angle-40 {
    transform: rotate(calc(40deg - 90deg));
}
.angle-41 {
    transform: rotate(calc(41deg - 90deg));
}
.angle-42 {
    transform: rotate(calc(42deg - 90deg));
}
.angle-43 {
    transform: rotate(calc(43deg - 90deg));
}
.angle-44 {
    transform: rotate(calc(44deg - 90deg));
}
.angle-45 {
    transform: rotate(calc(45deg - 90deg));
}
.angle-46 {
    transform: rotate(calc(46deg - 90deg));
}
.angle-47 {
    transform: rotate(calc(47deg - 90deg));
}
.angle-48 {
    transform: rotate(calc(48deg - 90deg));
}
.angle-49 {
    transform: rotate(calc(49deg - 90deg));
}
.angle-50 {
    transform: rotate(calc(50deg - 90deg));
}
.angle-51 {
    transform: rotate(calc(51deg - 90deg));
}
.angle-52 {
    transform: rotate(calc(52deg - 90deg));
}
.angle-53 {
    transform: rotate(calc(53deg - 90deg));
}
.angle-54 {
    transform: rotate(calc(54deg - 90deg));
}
.angle-55 {
    transform: rotate(calc(55deg - 90deg));
}
.angle-56 {
    transform: rotate(calc(56deg - 90deg));
}
.angle-57 {
    transform: rotate(calc(57deg - 90deg));
}
.angle-58 {
    transform: rotate(calc(58deg - 90deg));
}
.angle-59 {
    transform: rotate(calc(59deg - 90deg));
}
.angle-60 {
    transform: rotate(calc(60deg - 90deg));
}
.angle-61 {
    transform: rotate(calc(61deg - 90deg));
}
.angle-62 {
    transform: rotate(calc(62deg - 90deg));
}
.angle-63 {
    transform: rotate(calc(63deg - 90deg));
}
.angle-64 {
    transform: rotate(calc(64deg - 90deg));
}
.angle-65 {
    transform: rotate(calc(65deg - 90deg));
}
.angle-66 {
    transform: rotate(calc(66deg - 90deg));
}
.angle-67 {
    transform: rotate(calc(67deg - 90deg));
}
.angle-68 {
    transform: rotate(calc(68deg - 90deg));
}
.angle-69 {
    transform: rotate(calc(69deg - 90deg));
}
.angle-70 {
    transform: rotate(calc(70deg - 90deg));
}
.angle-71 {
    transform: rotate(calc(71deg - 90deg));
}
.angle-72 {
    transform: rotate(calc(72deg - 90deg));
}
.angle-73 {
    transform: rotate(calc(73deg - 90deg));
}
.angle-74 {
    transform: rotate(calc(74deg - 90deg));
}
.angle-75 {
    transform: rotate(calc(75deg - 90deg));
}
.angle-76 {
    transform: rotate(calc(76deg - 90deg));
}
.angle-77 {
    transform: rotate(calc(77deg - 90deg));
}
.angle-78 {
    transform: rotate(calc(78deg - 90deg));
}
.angle-79 {
    transform: rotate(calc(79deg - 90deg));
}
.angle-80 {
    transform: rotate(calc(80deg - 90deg));
}
.angle-81 {
    transform: rotate(calc(81deg - 90deg));
}
.angle-82 {
    transform: rotate(calc(82deg - 90deg));
}
.angle-83 {
    transform: rotate(calc(83deg - 90deg));
}
.angle-84 {
    transform: rotate(calc(84deg - 90deg));
}
.angle-85 {
    transform: rotate(calc(85deg - 90deg));
}
.angle-86 {
    transform: rotate(calc(86deg - 90deg));
}
.angle-87 {
    transform: rotate(calc(87deg - 90deg));
}
.angle-88 {
    transform: rotate(calc(88deg - 90deg));
}
.angle-89 {
    transform: rotate(calc(89deg - 90deg));
}
.angle-90 {
    transform: rotate(calc(90deg - 90deg));
}
.angle-91 {
    transform: rotate(calc(91deg - 90deg));
}
.angle-92 {
    transform: rotate(calc(92deg - 90deg));
}
.angle-93 {
    transform: rotate(calc(93deg - 90deg));
}
.angle-94 {
    transform: rotate(calc(94deg - 90deg));
}
.angle-95 {
    transform: rotate(calc(95deg - 90deg));
}
.angle-96 {
    transform: rotate(calc(96deg - 90deg));
}
.angle-97 {
    transform: rotate(calc(97deg - 90deg));
}
.angle-98 {
    transform: rotate(calc(98deg - 90deg));
}
.angle-99 {
    transform: rotate(calc(99deg - 90deg));
}
.angle-100 {
    transform: rotate(calc(100deg - 90deg));
}
.angle-101 {
    transform: rotate(calc(101deg - 90deg));
}
.angle-102 {
    transform: rotate(calc(102deg - 90deg));
}
.angle-103 {
    transform: rotate(calc(103deg - 90deg));
}
.angle-104 {
    transform: rotate(calc(104deg - 90deg));
}
.angle-105 {
    transform: rotate(calc(105deg - 90deg));
}
.angle-106 {
    transform: rotate(calc(106deg - 90deg));
}
.angle-107 {
    transform: rotate(calc(107deg - 90deg));
}
.angle-108 {
    transform: rotate(calc(108deg - 90deg));
}
.angle-109 {
    transform: rotate(calc(109deg - 90deg));
}
.angle-110 {
    transform: rotate(calc(110deg - 90deg));
}
.angle-111 {
    transform: rotate(calc(111deg - 90deg));
}
.angle-112 {
    transform: rotate(calc(112deg - 90deg));
}
.angle-113 {
    transform: rotate(calc(113deg - 90deg));
}
.angle-114 {
    transform: rotate(calc(114deg - 90deg));
}
.angle-115 {
    transform: rotate(calc(115deg - 90deg));
}
.angle-116 {
    transform: rotate(calc(116deg - 90deg));
}
.angle-117 {
    transform: rotate(calc(117deg - 90deg));
}
.angle-118 {
    transform: rotate(calc(118deg - 90deg));
}
.angle-119 {
    transform: rotate(calc(119deg - 90deg));
}
.angle-120 {
    transform: rotate(calc(120deg - 90deg));
}
.angle-121 {
    transform: rotate(calc(121deg - 90deg));
}
.angle-122 {
    transform: rotate(calc(122deg - 90deg));
}
.angle-123 {
    transform: rotate(calc(123deg - 90deg));
}
.angle-124 {
    transform: rotate(calc(124deg - 90deg));
}
.angle-125 {
    transform: rotate(calc(125deg - 90deg));
}
.angle-126 {
    transform: rotate(calc(126deg - 90deg));
}
.angle-127 {
    transform: rotate(calc(127deg - 90deg));
}
.angle-128 {
    transform: rotate(calc(128deg - 90deg));
}
.angle-129 {
    transform: rotate(calc(129deg - 90deg));
}
.angle-130 {
    transform: rotate(calc(130deg - 90deg));
}
.angle-131 {
    transform: rotate(calc(131deg - 90deg));
}
.angle-132 {
    transform: rotate(calc(132deg - 90deg));
}
.angle-133 {
    transform: rotate(calc(133deg - 90deg));
}
.angle-134 {
    transform: rotate(calc(134deg - 90deg));
}
.angle-135 {
    transform: rotate(calc(135deg - 90deg));
}
.angle-136 {
    transform: rotate(calc(136deg - 90deg));
}
.angle-137 {
    transform: rotate(calc(137deg - 90deg));
}
.angle-138 {
    transform: rotate(calc(138deg - 90deg));
}
.angle-139 {
    transform: rotate(calc(139deg - 90deg));
}
.angle-140 {
    transform: rotate(calc(140deg - 90deg));
}
.angle-141 {
    transform: rotate(calc(141deg - 90deg));
}
.angle-142 {
    transform: rotate(calc(142deg - 90deg));
}
.angle-143 {
    transform: rotate(calc(143deg - 90deg));
}
.angle-144 {
    transform: rotate(calc(144deg - 90deg));
}
.angle-145 {
    transform: rotate(calc(145deg - 90deg));
}
.angle-146 {
    transform: rotate(calc(146deg - 90deg));
}
.angle-147 {
    transform: rotate(calc(147deg - 90deg));
}
.angle-148 {
    transform: rotate(calc(148deg - 90deg));
}
.angle-149 {
    transform: rotate(calc(149deg - 90deg));
}
.angle-150 {
    transform: rotate(calc(150deg - 90deg));
}
.angle-151 {
    transform: rotate(calc(151deg - 90deg));
}
.angle-152 {
    transform: rotate(calc(152deg - 90deg));
}
.angle-153 {
    transform: rotate(calc(153deg - 90deg));
}
.angle-154 {
    transform: rotate(calc(154deg - 90deg));
}
.angle-155 {
    transform: rotate(calc(155deg - 90deg));
}
.angle-156 {
    transform: rotate(calc(156deg - 90deg));
}
.angle-157 {
    transform: rotate(calc(157deg - 90deg));
}
.angle-158 {
    transform: rotate(calc(158deg - 90deg));
}
.angle-159 {
    transform: rotate(calc(159deg - 90deg));
}
.angle-160 {
    transform: rotate(calc(160deg - 90deg));
}
.angle-161 {
    transform: rotate(calc(161deg - 90deg));
}
.angle-162 {
    transform: rotate(calc(162deg - 90deg));
}
.angle-163 {
    transform: rotate(calc(163deg - 90deg));
}
.angle-164 {
    transform: rotate(calc(164deg - 90deg));
}
.angle-165 {
    transform: rotate(calc(165deg - 90deg));
}
.angle-166 {
    transform: rotate(calc(166deg - 90deg));
}
.angle-167 {
    transform: rotate(calc(167deg - 90deg));
}
.angle-168 {
    transform: rotate(calc(168deg - 90deg));
}
.angle-169 {
    transform: rotate(calc(169deg - 90deg));
}
.angle-170 {
    transform: rotate(calc(170deg - 90deg));
}
.angle-171 {
    transform: rotate(calc(171deg - 90deg));
}
.angle-172 {
    transform: rotate(calc(172deg - 90deg));
}
.angle-173 {
    transform: rotate(calc(173deg - 90deg));
}
.angle-174 {
    transform: rotate(calc(174deg - 90deg));
}
.angle-175 {
    transform: rotate(calc(175deg - 90deg));
}
.angle-176 {
    transform: rotate(calc(176deg - 90deg));
}
.angle-177 {
    transform: rotate(calc(177deg - 90deg));
}
.angle-178 {
    transform: rotate(calc(178deg - 90deg));
}
.angle-179 {
    transform: rotate(calc(179deg - 90deg));
}
.angle-180 {
    transform: rotate(calc(180deg - 90deg));
}
.angle-181 {
    transform: rotate(calc(181deg - 90deg));
}
.angle-182 {
    transform: rotate(calc(182deg - 90deg));
}
.angle-183 {
    transform: rotate(calc(183deg - 90deg));
}
.angle-184 {
    transform: rotate(calc(184deg - 90deg));
}
.angle-185 {
    transform: rotate(calc(185deg - 90deg));
}
.angle-186 {
    transform: rotate(calc(186deg - 90deg));
}
.angle-187 {
    transform: rotate(calc(187deg - 90deg));
}
.angle-188 {
    transform: rotate(calc(188deg - 90deg));
}
.angle-189 {
    transform: rotate(calc(189deg - 90deg));
}
.angle-190 {
    transform: rotate(calc(190deg - 90deg));
}
.angle-191 {
    transform: rotate(calc(191deg - 90deg));
}
.angle-192 {
    transform: rotate(calc(192deg - 90deg));
}
.angle-193 {
    transform: rotate(calc(193deg - 90deg));
}
.angle-194 {
    transform: rotate(calc(194deg - 90deg));
}
.angle-195 {
    transform: rotate(calc(195deg - 90deg));
}
.angle-196 {
    transform: rotate(calc(196deg - 90deg));
}
.angle-197 {
    transform: rotate(calc(197deg - 90deg));
}
.angle-198 {
    transform: rotate(calc(198deg - 90deg));
}
.angle-199 {
    transform: rotate(calc(199deg - 90deg));
}
.angle-200 {
    transform: rotate(calc(200deg - 90deg));
}
.angle-201 {
    transform: rotate(calc(201deg - 90deg));
}
.angle-202 {
    transform: rotate(calc(202deg - 90deg));
}
.angle-203 {
    transform: rotate(calc(203deg - 90deg));
}
.angle-204 {
    transform: rotate(calc(204deg - 90deg));
}
.angle-205 {
    transform: rotate(calc(205deg - 90deg));
}
.angle-206 {
    transform: rotate(calc(206deg - 90deg));
}
.angle-207 {
    transform: rotate(calc(207deg - 90deg));
}
.angle-208 {
    transform: rotate(calc(208deg - 90deg));
}
.angle-209 {
    transform: rotate(calc(209deg - 90deg));
}
.angle-210 {
    transform: rotate(calc(210deg - 90deg));
}
.angle-211 {
    transform: rotate(calc(211deg - 90deg));
}
.angle-212 {
    transform: rotate(calc(212deg - 90deg));
}
.angle-213 {
    transform: rotate(calc(213deg - 90deg));
}
.angle-214 {
    transform: rotate(calc(214deg - 90deg));
}
.angle-215 {
    transform: rotate(calc(215deg - 90deg));
}
.angle-216 {
    transform: rotate(calc(216deg - 90deg));
}
.angle-217 {
    transform: rotate(calc(217deg - 90deg));
}
.angle-218 {
    transform: rotate(calc(218deg - 90deg));
}
.angle-219 {
    transform: rotate(calc(219deg - 90deg));
}
.angle-220 {
    transform: rotate(calc(220deg - 90deg));
}
.angle-221 {
    transform: rotate(calc(221deg - 90deg));
}
.angle-222 {
    transform: rotate(calc(222deg - 90deg));
}
.angle-223 {
    transform: rotate(calc(223deg - 90deg));
}
.angle-224 {
    transform: rotate(calc(224deg - 90deg));
}
.angle-225 {
    transform: rotate(calc(225deg - 90deg));
}
.angle-226 {
    transform: rotate(calc(226deg - 90deg));
}
.angle-227 {
    transform: rotate(calc(227deg - 90deg));
}
.angle-228 {
    transform: rotate(calc(228deg - 90deg));
}
.angle-229 {
    transform: rotate(calc(229deg - 90deg));
}
.angle-230 {
    transform: rotate(calc(230deg - 90deg));
}
.angle-231 {
    transform: rotate(calc(231deg - 90deg));
}
.angle-232 {
    transform: rotate(calc(232deg - 90deg));
}
.angle-233 {
    transform: rotate(calc(233deg - 90deg));
}
.angle-234 {
    transform: rotate(calc(234deg - 90deg));
}
.angle-235 {
    transform: rotate(calc(235deg - 90deg));
}
.angle-236 {
    transform: rotate(calc(236deg - 90deg));
}
.angle-237 {
    transform: rotate(calc(237deg - 90deg));
}
.angle-238 {
    transform: rotate(calc(238deg - 90deg));
}
.angle-239 {
    transform: rotate(calc(239deg - 90deg));
}
.angle-240 {
    transform: rotate(calc(240deg - 90deg));
}
.angle-241 {
    transform: rotate(calc(241deg - 90deg));
}
.angle-242 {
    transform: rotate(calc(242deg - 90deg));
}
.angle-243 {
    transform: rotate(calc(243deg - 90deg));
}
.angle-244 {
    transform: rotate(calc(244deg - 90deg));
}
.angle-245 {
    transform: rotate(calc(245deg - 90deg));
}
.angle-246 {
    transform: rotate(calc(246deg - 90deg));
}
.angle-247 {
    transform: rotate(calc(247deg - 90deg));
}
.angle-248 {
    transform: rotate(calc(248deg - 90deg));
}
.angle-249 {
    transform: rotate(calc(249deg - 90deg));
}
.angle-250 {
    transform: rotate(calc(250deg - 90deg));
}
.angle-251 {
    transform: rotate(calc(251deg - 90deg));
}
.angle-252 {
    transform: rotate(calc(252deg - 90deg));
}
.angle-253 {
    transform: rotate(calc(253deg - 90deg));
}
.angle-254 {
    transform: rotate(calc(254deg - 90deg));
}
.angle-255 {
    transform: rotate(calc(255deg - 90deg));
}
.angle-256 {
    transform: rotate(calc(256deg - 90deg));
}
.angle-257 {
    transform: rotate(calc(257deg - 90deg));
}
.angle-258 {
    transform: rotate(calc(258deg - 90deg));
}
.angle-259 {
    transform: rotate(calc(259deg - 90deg));
}
.angle-260 {
    transform: rotate(calc(260deg - 90deg));
}
.angle-261 {
    transform: rotate(calc(261deg - 90deg));
}
.angle-262 {
    transform: rotate(calc(262deg - 90deg));
}
.angle-263 {
    transform: rotate(calc(263deg - 90deg));
}
.angle-264 {
    transform: rotate(calc(264deg - 90deg));
}
.angle-265 {
    transform: rotate(calc(265deg - 90deg));
}
.angle-266 {
    transform: rotate(calc(266deg - 90deg));
}
.angle-267 {
    transform: rotate(calc(267deg - 90deg));
}
.angle-268 {
    transform: rotate(calc(268deg - 90deg));
}
.angle-269 {
    transform: rotate(calc(269deg - 90deg));
}
.angle-270 {
    transform: rotate(calc(270deg - 90deg));
}
.angle-271 {
    transform: rotate(calc(271deg - 90deg));
}
.angle-272 {
    transform: rotate(calc(272deg - 90deg));
}
.angle-273 {
    transform: rotate(calc(273deg - 90deg));
}
.angle-274 {
    transform: rotate(calc(274deg - 90deg));
}
.angle-275 {
    transform: rotate(calc(275deg - 90deg));
}
.angle-276 {
    transform: rotate(calc(276deg - 90deg));
}
.angle-277 {
    transform: rotate(calc(277deg - 90deg));
}
.angle-278 {
    transform: rotate(calc(278deg - 90deg));
}
.angle-279 {
    transform: rotate(calc(279deg - 90deg));
}
.angle-280 {
    transform: rotate(calc(280deg - 90deg));
}
.angle-281 {
    transform: rotate(calc(281deg - 90deg));
}
.angle-282 {
    transform: rotate(calc(282deg - 90deg));
}
.angle-283 {
    transform: rotate(calc(283deg - 90deg));
}
.angle-284 {
    transform: rotate(calc(284deg - 90deg));
}
.angle-285 {
    transform: rotate(calc(285deg - 90deg));
}
.angle-286 {
    transform: rotate(calc(286deg - 90deg));
}
.angle-287 {
    transform: rotate(calc(287deg - 90deg));
}
.angle-288 {
    transform: rotate(calc(288deg - 90deg));
}
.angle-289 {
    transform: rotate(calc(289deg - 90deg));
}
.angle-290 {
    transform: rotate(calc(290deg - 90deg));
}
.angle-291 {
    transform: rotate(calc(291deg - 90deg));
}
.angle-292 {
    transform: rotate(calc(292deg - 90deg));
}
.angle-293 {
    transform: rotate(calc(293deg - 90deg));
}
.angle-294 {
    transform: rotate(calc(294deg - 90deg));
}
.angle-295 {
    transform: rotate(calc(295deg - 90deg));
}
.angle-296 {
    transform: rotate(calc(296deg - 90deg));
}
.angle-297 {
    transform: rotate(calc(297deg - 90deg));
}
.angle-298 {
    transform: rotate(calc(298deg - 90deg));
}
.angle-299 {
    transform: rotate(calc(299deg - 90deg));
}
.angle-300 {
    transform: rotate(calc(300deg - 90deg));
}
.angle-301 {
    transform: rotate(calc(301deg - 90deg));
}
.angle-302 {
    transform: rotate(calc(302deg - 90deg));
}
.angle-303 {
    transform: rotate(calc(303deg - 90deg));
}
.angle-304 {
    transform: rotate(calc(304deg - 90deg));
}
.angle-305 {
    transform: rotate(calc(305deg - 90deg));
}
.angle-306 {
    transform: rotate(calc(306deg - 90deg));
}
.angle-307 {
    transform: rotate(calc(307deg - 90deg));
}
.angle-308 {
    transform: rotate(calc(308deg - 90deg));
}
.angle-309 {
    transform: rotate(calc(309deg - 90deg));
}
.angle-310 {
    transform: rotate(calc(310deg - 90deg));
}
.angle-311 {
    transform: rotate(calc(311deg - 90deg));
}
.angle-312 {
    transform: rotate(calc(312deg - 90deg));
}
.angle-313 {
    transform: rotate(calc(313deg - 90deg));
}
.angle-314 {
    transform: rotate(calc(314deg - 90deg));
}
.angle-315 {
    transform: rotate(calc(315deg - 90deg));
}
.angle-316 {
    transform: rotate(calc(316deg - 90deg));
}
.angle-317 {
    transform: rotate(calc(317deg - 90deg));
}
.angle-318 {
    transform: rotate(calc(318deg - 90deg));
}
.angle-319 {
    transform: rotate(calc(319deg - 90deg));
}
.angle-320 {
    transform: rotate(calc(320deg - 90deg));
}
.angle-321 {
    transform: rotate(calc(321deg - 90deg));
}
.angle-322 {
    transform: rotate(calc(322deg - 90deg));
}
.angle-323 {
    transform: rotate(calc(323deg - 90deg));
}
.angle-324 {
    transform: rotate(calc(324deg - 90deg));
}
.angle-325 {
    transform: rotate(calc(325deg - 90deg));
}
.angle-326 {
    transform: rotate(calc(326deg - 90deg));
}
.angle-327 {
    transform: rotate(calc(327deg - 90deg));
}
.angle-328 {
    transform: rotate(calc(328deg - 90deg));
}
.angle-329 {
    transform: rotate(calc(329deg - 90deg));
}
.angle-330 {
    transform: rotate(calc(330deg - 90deg));
}
.angle-331 {
    transform: rotate(calc(331deg - 90deg));
}
.angle-332 {
    transform: rotate(calc(332deg - 90deg));
}
.angle-333 {
    transform: rotate(calc(333deg - 90deg));
}
.angle-334 {
    transform: rotate(calc(334deg - 90deg));
}
.angle-335 {
    transform: rotate(calc(335deg - 90deg));
}
.angle-336 {
    transform: rotate(calc(336deg - 90deg));
}
.angle-337 {
    transform: rotate(calc(337deg - 90deg));
}
.angle-338 {
    transform: rotate(calc(338deg - 90deg));
}
.angle-339 {
    transform: rotate(calc(339deg - 90deg));
}
.angle-340 {
    transform: rotate(calc(340deg - 90deg));
}
.angle-341 {
    transform: rotate(calc(341deg - 90deg));
}
.angle-342 {
    transform: rotate(calc(342deg - 90deg));
}
.angle-343 {
    transform: rotate(calc(343deg - 90deg));
}
.angle-344 {
    transform: rotate(calc(344deg - 90deg));
}
.angle-345 {
    transform: rotate(calc(345deg - 90deg));
}
.angle-346 {
    transform: rotate(calc(346deg - 90deg));
}
.angle-347 {
    transform: rotate(calc(347deg - 90deg));
}
.angle-348 {
    transform: rotate(calc(348deg - 90deg));
}
.angle-349 {
    transform: rotate(calc(349deg - 90deg));
}
.angle-350 {
    transform: rotate(calc(350deg - 90deg));
}
.angle-351 {
    transform: rotate(calc(351deg - 90deg));
}
.angle-352 {
    transform: rotate(calc(352deg - 90deg));
}
.angle-353 {
    transform: rotate(calc(353deg - 90deg));
}
.angle-354 {
    transform: rotate(calc(354deg - 90deg));
}
.angle-355 {
    transform: rotate(calc(355deg - 90deg));
}
.angle-356 {
    transform: rotate(calc(356deg - 90deg));
}
.angle-357 {
    transform: rotate(calc(357deg - 90deg));
}
.angle-358 {
    transform: rotate(calc(358deg - 90deg));
}
.angle-359 {
    transform: rotate(calc(359deg - 90deg));
}
.angle-360 {
    transform: rotate(calc(360deg - 90deg));
}
