.navgation-link{
    @apply inline-flex flex-col items-center justify-center px-2 text-gray-500
}

.navgation-link.active{
    @apply text-black
}

.navgation-link .bn-link-text{
    @apply text-sm
}

.navgation-link.active .bn-link-text{
    font-size: 16px;
    @apply font-bold
}