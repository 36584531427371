@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --header-shadow-color: #dadce0;
  --bgLogoSecondaryColor: #0a284d;
}


* {
  font-family: "Fira Sans", sans-serif;
}




body {
  overscroll-behavior-y: contain;
  user-select: none;
  /* background: var(--bgLogoSecondaryColor); */
  @apply bg-gray-100
  /* background: linear-gradient(
      to bottom,
      var(--primary-light-color),
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb)); */
}


header {
  height: 72px;
}

.dvh-full{
  height: 100dvh;
}

#content{
  height: calc(100dvh - (1*72px));
  overflow: auto;
}





.underline-shadow {
  box-shadow: inset 0 -1px 0 0 var(--header-shadow-color);
}

.site-menu-item {
  @apply p-1 m-1 hover:cursor-pointer flex items-center pl-4 w-full border-b;
}

.site-menu-item span {
  @apply text-3xl text-gray-600 w-1/4 flex;
}

.background-fade{
  @apply w-screen bg-black top-0 left-0 fixed opacity-50;
}

.center{
  @apply flex justify-center items-center
}

.innerContainer{
  height: calc(100% - 72px);
  overflow: auto;
}


.innerContainerDvh{
  height: calc(100dvh - 72px);
  overflow: auto;
  @apply max-w-3xl mx-auto
}


.li-vehicle-list{
  box-shadow: 0px 0px 4px #0000002e;
  @apply py-5 bg-white rounded-sm px-2
}


#reportAnIssue label{
  @apply block mb-2 font-medium text-gray-900
}

.overlay{
  height: 100dvh;
  @apply w-full flex justify-center items-center bg-black opacity-50 absolute left-0 top-0 z-50
}

.blankScreen{
  opacity: 1;
  @apply bg-white
}

.bottomTab{
  height: 72px;
}
.dots-texture {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23312E81' fill-opacity='0.4' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
}