.hidden {
    display: none;
}


.pulser {
    @apply w-7 h-7 rounded-full relative bg-red-600
}

.pulser::after {
    animation: pulse 1000ms cubic-bezier(0.9, 0.7, 0.5, 0.9) infinite;
}

@keyframes pulse {
    0% {
        opacity: 0;
    }

    50% {
        transform: scale(1.4);
        opacity: 0.4;
    }
}

.pulser::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 50%;
    z-index: -1;
    @apply bg-red-600
}



.vehicleAdvMrk{
    color: #263238;
    position: relative;
    transition: all 0.3s ease-out;
    @apply bg-blue-600 items-center rounded-full flex text-sm gap-4 h-14 w-14 justify-center p-1
}

.vehicleAdvMrk::after {
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-top: 9px solid #ff0000;
    content: "";
    height: 0;
    left: 50%;
    position: absolute;
    top: 95%;
    transform: translate(-50%, 0);
    transition: all 0.3s ease-out;
    width: 0;
    z-index: 1;

    @apply border-t-blue-600
}

.vehicleAdvMrk .icon {
    align-items: center;
    display: flex;
    justify-content: center;
}

.vehicleAdvMrk .details {
    @apply flex-1 flex-col hidden
}

.map-vehicle-info.vehicleAdvMrk .details {
    display: flex;
}


.map-vehicle-info.vehicleAdvMrk {
    box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.2);
    width: auto;
    height: auto;
    @apply bg-blue-600 text-white rounded-lg px-2 py-4
}

.map-vehicle-info.vehicleAdvMrk .icon {
    @apply hidden;
}


.map-vehicle-info.vehicleAdvMrk::after {
    border-top: 18px solid #FFFFFF;
    @apply border-t-blue-600
}


.vehicleAdvMrk.map-vehicle-info .details {
    display: flex;
}

.vehicleAdvMrk.map-vehicle-info .icon svg {
    width: auto;
    @apply h-20
}

.details h3{
    @apply text-center text-xl my-2 font-semibold
}

.details ul{
    @apply border w-full text-sm list-none bg-white text-black
}

.details li{
    @apply border-b p-2
}