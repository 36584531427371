.amr-toastContainer{
    inset: 16px; 
    pointer-events: none;
    @apply z-30 fixed
}

.toast-message{
    @apply p-2 rounded-lg
}

.toast-messageContainer{
    pointer-events: auto;
    @apply rounded-lg flex p-2
}

.amr-btnToastClose{
    @apply p-2
}